<ion-app>
  <ion-header>
    <div
      style="
        background-color: #000;
        height: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <img src="assets/South Florida Booting Logo.jpg" height="60" />
    </div>
  </ion-header>
  <ion-content>
    <main>
      <!-- Starting point -->
      <section [@sectionHiddenShown]="step == 0 ? 'shown' : 'hidden'">
        <h1>Pay For My Boot</h1>
        <ion-button
          class="w-100"
          color="secondary"
          (click)="chooseTrack('boot'); next()"
          >Pay Now
        </ion-button>
        <ion-button
          class="w-100"
          color="secondary"
          (click)="resendReceipt()"
          >Re-send Receipt
        </ion-button>
      </section>

      <!-- Invoice starting point -->
      <section
        [@sectionHiddenShown]="
          (step == 1 || step == 2) && track == 'invoice' ? 'shown' : 'hidden'
        "
        data-track="invoice"
      >
        <h1>Let's get started.</h1>
        <ion-item class="w-100" lines="none">
          <ion-input
            label="Invoice Number:"
            labelPlacement="stacked"
            [disabled]="step == 2 || step == 3"
            [value]="invoiceNumber"
            (ionChange)="setInvoiceNumber($event.target.value)"
          ></ion-input>
        </ion-item>
        <div
          class="w-100"
          style="margin-top: 5px"
          [@elementHiddenShown]="step !== 2 ? 'hidden' : 'shown'"
        >
          <ion-item>
            <ion-input
              label="Payment Amount (USD):"
              labelPlacement="stacked"
              type="number"
              (ionChange)="setPaymentAmount($event.target.value)"
              [value]="paymentAmount"
            >
            </ion-input>
          </ion-item>
        </div>
        <div class="w-100">
          <ion-button
            [@buttonHiddenShown]="step == 2 || step == 3 ? 'hidden' : 'shown'"
            class="w-100"
            color="secondary"
            (click)="findInvoice()"
            >Pay Invoice</ion-button
          >
          <ion-button
            [@buttonHiddenShown]="step == 0 || step == 1 ? 'hidden' : 'shown'"
            class="w-100"
            color="primary"
            (click)="makeInvoicePayment()"
            >Make Payment</ion-button
          >
        </div>
      </section>

      <!-- Boot starting point -->
      <section
        [@sectionHiddenShown]="
          (step == 1 || step == 2) && track == 'boot' ? 'shown' : 'hidden'
        "
        data-track="boot"
      >
        <h1>Let's get started.</h1>
        <ion-item class="w-100" lines="none">
          <ion-input
            label="License Plate Number:"
            labelPlacement="stacked"
            [disabled]="step == 2 || step == 3"
            [value]="licensePlateNumber"
            placeholder="ex. A1B-C32"
            (ionChange)="licensePlateNumber = $event.target.value"
          ></ion-input>
        </ion-item>
        <table id="boot-table" [hidden]="step == 1">
          <thead>
            <tr>
              <td>Price</td>
              <td>Timestamp</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let boot of foundBoots; trackBy: trackBoots">
              <td>${{ boot.bootPrice }}</td>
              <td>{{ boot.violationDate }}</td>
              <td>
                <ion-button (click)="selectBoot(boot)" color="primary"
                  >Pay</ion-button
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div class="w-100">
          <ion-button
            [@buttonHiddenShown]="step == 2 || step == 3 ? 'hidden' : 'shown'"
            class="w-100"
            color="secondary"
            (click)="findBoots()"
            >Find Boot</ion-button
          >
        </div>
      </section>

      <!-- View Boot -->
      <section
        [@sectionHiddenShown]="
          step == 3 && track == 'boot' ? 'shown' : 'hidden'
        "
        data-track="boot"
      >
        <h2>Boot Information</h2>
        <div id="boot-preview">
          <ion-list className="resource-form">
            <ion-item>
              <ion-textarea
                label="Violation Timestamp:"
                labelPlacement="stacked"
                type="text"
                [disabled]="true"
                [value]="boot.violationDate"
              ></ion-textarea>
            </ion-item>
            <ion-item>
              <ion-input
                label="Reason for Boot:"
                labelPlacement="stacked"
                type="text"
                [disabled]="true"
                [value]="boot.violationType"
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-input
                label="Boot Price:"
                labelPlacement="stacked"
                [disabled]="true"
                [value]="boot.bootPrice"
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-textarea
                label="Violation Notes:"
                labelPlacement="stacked"
                type="text"
                [disabled]="true"
                [value]="boot.notes"
              ></ion-textarea>
            </ion-item>
            <ion-item>
              <ion-input
                label="License Plate Number:"
                labelPlacement="stacked"
                type="text"
                [disabled]="true"
                [value]="boot.licensePlateNumber"
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-input
                label="License Plate State:"
                labelPlacement="stacked"
                type="text"
                [disabled]="true"
                [value]="boot.licensePlateState"
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-input
                label="Vehicle Color:"
                labelPlacement="stacked"
                type="text"
                [disabled]="true"
                [value]="boot.vehicleColor"
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-input
                label="Vehicle Make:"
                labelPlacement="stacked"
                type="text"
                [disabled]="true"
                [value]="boot.vehicleMake"
              ></ion-input>
            </ion-item>
            <ion-item>
              <ion-input
                label="Vehicle Model:"
                labelPlacement="stacked"
                type="text"
                [disabled]="true"
                [value]="boot.vehicleModel"
              ></ion-input>
            </ion-item>
            <ion-img *ngFor="let url of boot.pictureUrls" [src]="url"></ion-img>
          </ion-list>
        </div>
        <ion-button (click)="next()">Continue</ion-button>
      </section>

      <!-- Checkout Section -->
      <section
        id="payment-section"
        [@sectionHiddenShown]="
          step == (track == 'boot' ? 1 : 0) + 3 ||
          step == (track == 'boot' ? 1 : 0) + 4
            ? 'shown'
            : 'hidden'
        "
      >
        <h2>Pay {{ track == "invoice" ? "Invoice" : "Boot" }}</h2>
        <form
          id="checkout-form"
          class="w-100"
          novalidate
          (ngSubmit)="onSubmit()"
          [formGroup]="form"
          #demoForm="ngForm"
          novalidate
        >
        <div
          style="
            margin: 10px 0%;
            text-align: center;
            color: red;
          "
        >Please note that a convenience fee of 3% will be charged for payments made using a credit card.
        </div>
          <ion-item *ngIf="track == 'boot'">
            <ion-input
              label="Name of Vehicle Owner:"
              labelPlacement="stacked"
              type="text"
              formControlName="vehicleOwnerName"
              (ionChange)="step = (track == 'boot' ? 1 : 0) + 3"
            >
            </ion-input>
          </ion-item>
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              line-height: 0;
              margin: 10 0;
            "
            *ngIf="track == 'boot'"
          >
            <ion-checkbox
              formControlName="isVehicleOwner"
              (ionChange)="step = (track == 'boot' ? 1 : 0) + 3"
            ></ion-checkbox>
            <ion-label style="margin-left: 0.5em" position="stacked"
              >I am the vehicle owner.</ion-label
            >
          </div>
          <div style="display: flex; flex-direction: row" class="w-100">
            <ion-item style="flex: 1">
              <ion-input
                label="First Name:"
                labelPlacement="stacked"
                type="text"
                formControlName="firstName"
                (ionChange)="step = (track == 'boot' ? 1 : 0) + 3"
              >
              </ion-input>
            </ion-item>
            <ion-item style="flex: 1">
              <ion-input
                label="Last Name:"
                labelPlacement="stacked"
                type="text"
                formControlName="lastName"
                (ionChange)="step = (track == 'boot' ? 1 : 0) + 3"
              >
              </ion-input>
            </ion-item>
          </div>
          <h6 style="text-align: center;">Billing Address:</h6>
          <ion-item class="w-100">
            <ion-input
              label="Street Address:"
              labelPlacement="stacked"
              formControlName="streetAddress"
              type="text"
              autocomplete="street-address"
              (ionChange)="step = (track == 'boot' ? 1 : 0) + 3"
            ></ion-input>
          </ion-item>
          <ion-item class="w-100">
            <ion-input
              label="City:"
              labelPlacement="stacked"
              formControlName="city"
              type="text"
              autocomplete="address-level2"
              (ionChange)="step = (track == 'boot' ? 1 : 0) + 3"
            ></ion-input>
          </ion-item>
          <div style="display: flex; flex-direction: row" class="w-100">
            <ion-item style="flex: 1">
              <ion-input
                label="State:"
                labelPlacement="stacked"
                formControlName="state"
                type="text"
                autocomplete="address-level1"
                (ionChange)="step = (track == 'boot' ? 1 : 0) + 3"
              ></ion-input>
            </ion-item>
            <ion-item style="flex: 1">
              <ion-input
                label="Zip:"
                labelPlacement="stacked"
                formControlName="zip"
                type="text"
                autocomplete="address-level1"
                (ionChange)="step = (track == 'boot' ? 1 : 0) + 3"
              ></ion-input>
            </ion-item>
          </div>
          <h6 style="text-align: center;">Contact Information:</h6>
          <ion-item class="w-100">
            <ion-input
              label="Phone Number:"
              labelPlacement="stacked"
              formControlName="phoneNumber"
              type="tel"
              autocomplete="tel"
              (ionChange)="step = (track == 'boot' ? 1 : 0) + 3"
            >
            </ion-input>
          </ion-item>
          <ion-item class="w-100">
            <ion-input
              label="Email:"
              labelPlacement="stacked"
              formControlName="email"
              type="email"
              autocomplete="email"
              (ionChange)="step = (track == 'boot' ? 1 : 0) + 3"
            >
            </ion-input>
          </ion-item>
          
          <span
            style="margin: 10px 0;"
            *ngIf="boot.bootPrice === 75"
          >
            This transaction will include an additional 3% convenience fee.
          </span>

          <span style="margin: 10px 0; color: red" *ngIf="paymentError">
          <br>{{paymentError}}
          </span>


          <div class="w-100">
            <ion-button
                color="secondary"
                class="w-100 AcceptUI"
                data-billingAddressOptions='{"show":false, "required":false}' 
                [attr.data-apiLoginID]="environment.authorizeNetApiLoginId"
                [attr.data-clientKey]="environment.authorizeNetPublicClientKey"
                data-acceptUIFormBtnTxt="Save" 
                data-acceptUIFormHeaderTxt="Payment Information"
                data-paymentOptions='{"showCreditCard": true}' 
                data-responseHandler="handleAcceptJSSubmit"
              >Add Payment Info
            </ion-button>
          </div>
          <div class="w-100">
            <ion-button
              [@buttonHiddenShown]="
                step == (track == 'boot' ? 1 : 0) + 4 ||
                step == (track == 'boot' ? 1 : 0) + 5
                  ? 'hidden'
                  : 'shown'
              "
              class="w-100"
              color="secondary"
              (click)="validateForm()"
              >Pay Now</ion-button
            >
            <ion-button
              [@buttonHiddenShown]="
                step == (track == 'boot' ? 1 : 0) + 2 ||
                step == (track == 'boot' ? 1 : 0) + 3
                  ? 'hidden'
                  : 'shown'
              "
              class="w-100"
              color="success"
              type="submit"
              [disabled]="paymentInProgress"
              >Confirm Payment
            </ion-button>
          </div>
        </form>
      </section>
      <!-- Success page -->
      <section
        [@sectionHiddenShown]="
          step == (track == 'boot' ? 1 : 0) + 5 ? 'shown' : 'hidden'
        "
      >
        <h1 style="text-align: center; margin-bottom: 5px">
          Payment processing...
        </h1>
        <span
          style="text-align: center; margin-bottom: 5px"
          *ngIf="track === 'invoice'"
          >This may take a few minutes.  After the payment succeeds, you will receive a receipt via email.</span
        >
        <span style="text-align: center; color: red" *ngIf="track === 'boot'"
          >Please call 1-877-990-0004 to check on the status of your payment and to have your boot removed.</span
        >
        <span style="text-align: center" *ngIf="track === 'boot'"
          >You will also receive an updated receipt via email after the boot is removed.</span
        >
      </section>
    </main>
  </ion-content>
  <ion-footer>
    <div
      class="text-color"
      style="
        background-color: #000;
        height: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
      "
    >
      <a href="http://www.sflbooting.com/">
        <font color="#FFFFFF">South Florida Booting</font>
      </a>
    </div>
  </ion-footer>
</ion-app>
